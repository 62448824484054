import styled from "styled-components";

import { ShopTabsMenuItemStyledProps as Props } from "./ShopTabsMenuItem.types";

const ShopTabsMenuItemStyled = styled.div<Props>`
  & path {
    fill: ${props =>
      props.active ? "var(--palette-gray-s0-l51)" : "var(--palette-white)"};
  }

  && .TabsMenuItem__title {
    color: ${props =>
      props.active ? "var(--palette-gray-s0-l51)" : "var(--palette-white)"};
  }

  .ShopTabsMenuItem {
  }
`;

export default ShopTabsMenuItemStyled;
